<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-sm-6">
        <div>
          <router-link
            :to="`/snm/corsi`"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista corsi
          </router-link>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          partecipanti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <div
      class="row mt-5"
      v-if="detailIsLoaded && corso_partecipanti_list && !readOnly"
    >
      <div class="col-sm-3">
        <span class="text-gray-800 fs-6 text-start ms-5 me-4"
          >Rendi tutti:</span
        >
        <div class="btn-group">
          <a
            href="javascript:;"
            class="btn btn-light-success"
            @click.prevent="
              setAzioniAll('idoneoTrueAll', id_partecipanti, null, 1)
            "
            >Idonei</a
          >
          <a
            href="javascript:;"
            class="btn btn-light-danger"
            @click.prevent="
              setAzioniAll('idoneoFalseAll', id_partecipanti, null, 0)
            "
            >Non Idonei</a
          >
        </div>
      </div>
      <div class="col-sm-3">
        <span class="text-gray-800 fs-6 text-start ms-5 me-4"
          >Rendi tutti:</span
        >
        <div class="btn-group">
          <a
            href="javascript:;"
            class="btn btn-light-success"
            @click.prevent="
              setAzioniAll('presenteTrueAll', id_partecipanti, null, 1)
            "
            >Presenti</a
          >
          <a
            href="javascript:;"
            class="btn btn-light-danger"
            @click.prevent="
              setAzioniAll('presenteFalseAll', id_partecipanti, null, 0)
            "
            >Assenti</a
          >
        </div>
      </div>
      <div class="col-sm-3 text-center">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_iscritti"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta iscritti
          corso
        </button>
      </div>
      <ModalEsportaIscritti />
      <div class="col-sm-3 text-end">
        <div>
          <button
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_partecipante"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi partecipante
          </button>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        corso_partecipanti_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="corso_partecipanti_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsCorsoPartecipanti"
        @current-change="setCurrentPageCorsoPartecipanti"
      >
        <template v-slot:cell-nominativo_completo="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
            >
              {{ data.nominativo_completo }}</router-link
            >
          </div></template
        >
        <template v-slot:cell-codice_SNM="{ row: data }">
          <div class="text-center">
            {{ data.codice_SNM }}
          </div>
        </template>
        <template v-slot:cell-albo="{ row: data }">
          <div
            class="table-responsive card tab-long-xl"
            style="
              min-height: 0px !important;
              margin-bottom: 0px !important;
              background: transparent;
            "
          >
            <table
              class="table gy-3 gs-7 align-top"
              style="margin-bottom: 0px !important"
            >
              <tbody>
                <tr v-for="(item, i) in data.albo_qualifiche" :key="i" class="">
                  <td
                    v-if="item.numero_albo"
                    class="px-0 py-2"
                    style="width: 15%"
                  >
                    {{ item.numero_albo }}
                  </td>
                  <td
                    v-if="!item.numero_albo"
                    class="px-0 py-2"
                    style="width: 15%"
                  >
                    -
                  </td>
                  <td class="px-0 py-2" style="width: 85%">
                    {{ item.tipo_albo }}
                    -
                    {{ item.categoria }}
                    -
                    {{ item.qualifica }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-slot:cell-idoneo="{ row: data }">
          <div class="text-center">
            <i
              v-show="idoneiArray.indexOf(data.id) != -1"
              class="bi bi-check-lg fs-3 text-success"
            ></i>
            <i
              v-show="nonIdoneiArray.indexOf(data.id) != -1"
              class="bi bi-x-lg text-danger"
            ></i>
          </div>
        </template>
        <template v-slot:cell-scontato="{ row: data }">
          <div class="text-center">
            <i
              v-show="scontoArray.indexOf(data.id) != -1"
              class="bi bi-check-lg fs-3 text-success"
            ></i>
            <i
              v-show="nonScontoArray.indexOf(data.id) != -1"
              class="bi bi-x-lg text-danger"
            ></i>
          </div>
        </template>
        <template v-slot:cell-importo="{ row: data }">
          <div class="text-end">{{ data.importo }} €</div>
        </template>
        <template v-slot:cell-pagato="{ row: data }">
          <div
            class="text-center"
            :style="
              data.tipo_pagamento || data.riferimento_pagamento
                ? 'padding-bottom: 0.7rem'
                : ''
            "
            v-show="pagatoArray.indexOf(data.id) != -1"
          >
            <i class="bi bi-check-lg fs-3 text-success"></i>
            <Popper
              v-if="data.tipo_pagamento || data.riferimento_pagamento"
              arrow
              :content="
                'Pagamento: ' +
                data.tipo_pagamento +
                ' - ' +
                data.riferimento_pagamento
              "
            >
              <button
                class="bi bi-info-circle text-center text-gray-700"
                style="vertical-align: 0.7em; margin-left: -3px"
              ></button>
            </Popper>
          </div>
          <div class="text-center">
            <i
              v-show="nonPagatoArray.indexOf(data.id) != -1"
              class="bi bi-x-lg text-danger"
            ></i>
          </div>
        </template>
        <template v-slot:cell-approvato="{ row: data }">
          <div class="text-center">
            <i
              v-show="pagamentoApprovatoArray.indexOf(data.id) != -1"
              class="bi bi-check-lg fs-3 text-success"
            ></i>
            <i
              v-show="pagamentoNonApprovatoArray.indexOf(data.id) != -1"
              class="bi bi-x-lg text-danger"
            ></i>
          </div>
        </template>
        <template v-slot:cell-in_regola="{ row: data }">
          <div class="text-center">
            <i
              v-show="data.in_regola"
              class="bi bi-check-lg fs-3 text-success"
            ></i>
            <i v-show="!data.in_regola" class="bi bi-x-lg text-danger"></i>
          </div>
        </template>
        <template v-slot:cell-presente="{ row: data }">
          <div class="text-center">
            <i
              v-show="presentiArray.indexOf(data.id) != -1"
              class="bi bi-check-lg fs-3 text-success"
            ></i>
            <i
              v-show="assentiArray.indexOf(data.id) != -1"
              class="bi bi-x-lg text-danger"
            ></i>
          </div>
        </template>
        <template v-slot:cell-id_stato_iscrizione="{ row: data }">
          <h1
            v-show="nonIscrittoArray.indexOf(data.id) != -1"
            class="badge badge-light"
          >
            Non iscritto
          </h1>
          <h1
            v-show="inAttesaArray.indexOf(data.id) != -1"
            class="badge badge-light"
          >
            In attesa
          </h1>
          <h1
            v-show="accettataArray.indexOf(data.id) != -1"
            class="badge badge-light-success"
          >
            Accettata
          </h1>
          <h1
            v-show="rifiutataArray.indexOf(data.id) != -1"
            class="badge badge-light-danger"
          >
            Rifiutata
          </h1></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_documenti_partecipante"
              @click="
                setIdPersona(data.id_persona);
                setPersona(data.nominativo_completo);
                selectedPersonaObj = { ...data };
              "
            >
              <i
                :class="
                  data.hasDocumenti
                    ? 'bi bi-files text-success fs-5'
                    : 'bi bi-files text-danger fs-5'
                "
              ></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                @click="
                  zipDocumenti(
                    data.id_persona,
                    id_Corso,
                    data.nominativo_completo
                  )
                "
                v-if="data.hasDocumenti"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-download text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">Scarica documenti</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                v-if="!readOnly"
                href=""
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_documenti_partecipante"
                @click="
                  setIdPersona(data.id_persona);
                  setPersona(data.nominativo_completo);
                  selectedPersonaObj = { ...data };
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-files text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">Documenti</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  !readOnly &&
                  nonIdoneiArray.indexOf(data.id) != -1 &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('idoneoTrue', data.id, null, 1)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-lg fs-3 text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rendi idoneo</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  !readOnly &&
                  idoneiArray.indexOf(data.id) != -1 &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('idoneoFalse', data.id, null, 0)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-lg text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rendi non idoneo</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  assentiArray.indexOf(data.id) != -1 &&
                  !readOnly &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('presenteTrue', data.id, null, 1)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-lg fs-3 text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rendi presente</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  presentiArray.indexOf(data.id) != -1 &&
                  !readOnly &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('presenteFalse', data.id, null, 0)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-lg text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rendi assente</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  (inAttesaArray.indexOf(data.id) != -1 ||
                    nonIscrittoArray.indexOf(data.id) != -1) &&
                  !readOnly
                "
                @click.prevent="
                  setAzioni('iscrivi', data.id, data.id_persona, true)
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-lg fs-3 text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Accetta iscrizione</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  (inAttesaArray.indexOf(data.id) != -1 ||
                    nonIscrittoArray.indexOf(data.id) != -1) &&
                  !readOnly
                "
                @click.prevent="
                  setAzioni('rifiuta', data.id, data.id_persona, false)
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-lg text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Rifiuta iscrizione</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  nonScontoArray.indexOf(data.id) != -1 &&
                  !readOnly &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('scontoTrue', data.id, null, true)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-cash-coin text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Sconta corso</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="
                  scontoArray.indexOf(data.id) != -1 &&
                  !readOnly &&
                  rifiutataArray.indexOf(data.id) == -1
                "
                @click.prevent="setAzioni('scontoFalse', data.id, null, false)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-arrow-return-left text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Annulla sconto</span>
                    </div>
                  </div>
                </li>
              </a>
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal_pagamento_partecipante"
                @click.prevent="
                  setIdPersona(data.id_persona);
                  setPagamento(data.id, data.importo, data.nominativo_completo);
                "
                v-if="
                  data.data_pagamento == null &&
                  accettataArray.indexOf(data.id) != -1 &&
                  parseFloat(data.importo) > 0 &&
                  !readOnly
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-cash-coin text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Inserisci pagamento</span
                      >
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click.prevent="
                  setAzioni('remove', data.id, data.id_persona, 1)
                "
                v-if="!readOnly"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Elimina</span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <AddPartecipante @refreshList="refreshList" :id_Corso="id_Corso" />
    <ViewDocumentiPartecipante
      :id_Corso="id_Corso"
      :selectedIdPersona="selectedIdPersona"
      :selectedPersona="selectedPersona"
      :selectedPersonaObj="selectedPersonaObj"
      :stagioneCorso="stagioneCorso"
      :id_tipo_corso="id_tipo_corso"
      @refreshList="refreshList"
    />
    <AddPagamento
      @changeStatoPagato="changeStatoPagato"
      :selectedIdPartecipante="selectedIdPartecipante"
      :selectedIdPersona="selectedIdPersona"
      :selectedPersona="selectedPersona"
      :importoPagamento="importoPagamento"
      :corsoStagione="corsoStagione"
      :id_corso="id_Corso"
      :pagamentoIsLoaded="pagamentoIsLoaded"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import AddPartecipante from "../gestione-corsi/AddPartecipante.vue";
import ViewDocumentiPartecipante from "./ViewDocumentiPartecipante.vue";
import isEnabled from "@/composables/isEnabled.js";
import AddPagamento from "./AddPartecipantePagamento.vue";
import {
  deletePartecipanteCorso,
  setIdoneoPartecipantiCorso,
  setPresentePartecipantiCorso,
  setIscrizionePartecipanteCorso,
  setScontoPartecipanteCorso,
  detailCorso,
  downloadDocumenti,
} from "@/requests/snmCorsi";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { alertFailed } from "@/composables/swAlert";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import Popper from "vue3-popper";

import ModalEsportaIscritti from "./ModalEsportaIscritti.vue";

export default {
  name: "TableCorsoPartecipanti",
  components: {
    Datatable,
    AddPartecipante,
    ViewDocumentiPartecipante,
    AddPagamento,
    Loading,
    Popper,
    ModalEsportaIscritti,
  },
  emits: ["getCorsoPartecipantiList", "resetFilters"],
  props: {
    id_Corso: {
      type: [String, Number],
      required: true,
    },

    stagioneCorso: {
      type: Number,
      required: true,
    },
    id_tipo_corso: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsCorsoPartecipanti = (e) => {
      store.commit("setFetchRowsCorsoPartecipanti", e);
      emit("getCorsoPartecipantiList");
    };
    const setCurrentPageCorsoPartecipanti = (page) => {
      store.commit("setCurrentPageCorsoPartecipanti", page);
      emit("getCorsoPartecipantiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnCorsoPartecipanti", columnName);
      store.commit("setSortOrderCorsoPartecipanti", order);
      emit("getCorsoPartecipantiList");
    };

    const selectedIdPersona = ref(null);
    const selectedPersona = ref(null);
    const selectedPersonaObj = ref({});

    const setIdPersona = (id) => {
      selectedIdPersona.value = id;
    };

    const setPersona = (value) => {
      selectedPersona.value = value;
    };

    const refreshList = async () => {
      emit("getCorsoPartecipantiList");
      await getDetailCorso(props.id_Corso);
      // await detailCorso(props.id_Corso).then((res) => {
      //   detailIsLoaded.value = true;
      //   id_partecipanti.value = res.results[0].partecipanti;
      //   corsoStagione.value = res.results[0].stagione;
      // });
    };

    const tableHeader = ref([
      {
        name: "Persona",
        key: "nominativo_completo",
      },
      {
        name: "Cod. SNM",
        key: "codice_SNM",
        align: "center",
      },
      {
        name: "N.albo / Tipo / Categoria / Qualifica",
        key: "albo",
        sortable: false,
      },
      {
        name: "Idoneo",
        key: "idoneo",
        sortable: false,
        align: "center",
      },
      {
        name: "Sconto",
        key: "scontato",
        sortable: false,
        align: "center",
      },
      {
        name: "Imp.",
        key: "importo",
        align: "end",
      },
      {
        name: "Pagato",
        key: "pagato",
        sortable: false,
        align: "center",
      },
      {
        name: "Appr.",
        key: "approvato",
        sortable: false,
        align: "center",
      },
      {
        name: "In regola",
        key: "in_regola",
        sortable: false,
        align: "center",
      },
      {
        name: "Presente",
        key: "presente",
        sortable: false,
        align: "center",
      },
      {
        name: "Stato",
        key: "id_stato_iscrizione",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const detailIsLoaded = ref(false);
    const id_partecipanti = ref("");
    const corsoStagione = ref(null);

    const corso_partecipanti_list = computed(() =>
      store.getters.getStateFromName("resultscorso_partecipanti_list")
    );
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorso_partecipanti_list")
    );

    const idoneiArray = ref([]);
    const nonIdoneiArray = ref([]);
    const presentiArray = ref([]);
    const assentiArray = ref([]);
    const scontoArray = ref([]);
    const nonScontoArray = ref([]);
    const pagatoArray = ref([]);
    const nonPagatoArray = ref([]);
    const accettataArray = ref([]);
    const rifiutataArray = ref([]);
    const inAttesaArray = ref([]);
    const nonIscrittoArray = ref([]);
    const pagamentoApprovatoArray = ref([]);
    const pagamentoNonApprovatoArray = ref([]);

    watch(loaded, () => {
      idoneiArray.value.splice(0, idoneiArray.value.length);
      nonIdoneiArray.value.splice(0, nonIdoneiArray.value.length);
      scontoArray.value.splice(0, scontoArray.value.length);
      nonScontoArray.value.splice(0, nonScontoArray.value.length);
      pagatoArray.value.splice(0, pagatoArray.value.length);
      nonPagatoArray.value.splice(0, nonPagatoArray.value.length);
      accettataArray.value.splice(0, accettataArray.value.length);
      rifiutataArray.value.splice(0, rifiutataArray.value.length);
      inAttesaArray.value.splice(0, inAttesaArray.value.length);
      nonIscrittoArray.value.splice(0, nonIscrittoArray.value.length);
      presentiArray.value.splice(0, presentiArray.value.length);
      assentiArray.value.splice(0, assentiArray.value.length);
      pagamentoApprovatoArray.value.splice(
        0,
        pagamentoApprovatoArray.value.length
      );
      pagamentoNonApprovatoArray.value.splice(
        0,
        pagamentoNonApprovatoArray.value.length
      );
      corso_partecipanti_list.value.forEach((element) => {
        if (element.idoneo) idoneiArray.value.push(element.id);
        if (!element.idoneo) nonIdoneiArray.value.push(element.id);
        if (element.scontato) scontoArray.value.push(element.id);
        if (!element.scontato) nonScontoArray.value.push(element.id);
        if (element.data_pagamento) pagatoArray.value.push(element.id);
        if (!element.data_pagamento) nonPagatoArray.value.push(element.id);
        if (element.id_stato_iscrizione == 4)
          accettataArray.value.push(element.id);
        if (element.id_stato_iscrizione == 3)
          rifiutataArray.value.push(element.id);
        if (element.id_stato_iscrizione == 2)
          inAttesaArray.value.push(element.id);
        if (element.id_stato_iscrizione == 1)
          nonIscrittoArray.value.push(element.id);
        if (element.presente) presentiArray.value.push(element.id);
        if (!element.presente) assentiArray.value.push(element.id);
        if (element.approvato) pagamentoApprovatoArray.value.push(element.id);
        if (!element.approvato)
          pagamentoNonApprovatoArray.value.push(element.id);
      });
    });

    const getDetailCorso = (idCorso) => {
      detailIsLoaded.value = false;
      id_partecipanti.value = "";
      corsoStagione.value = null;
      detailCorso(idCorso).then((res) => {
        detailIsLoaded.value = true;
        id_partecipanti.value = res.results[0].partecipanti;
        corsoStagione.value = res.results[0].stagione;
      });
    };
    getDetailCorso(props.id_Corso);

    const isLoading = ref(false);

    const setAzioni = (tipo, id, id_persona, azione) => {
      azione = Boolean(azione);
      let message = "";
      switch (tipo) {
        case "remove":
          message =
            "Sei sicuro di voler eliminare il partecipante selezionato?";
          break;
        case "idoneoTrue":
          message =
            "Sei sicuro di voler rendere idoneo il partecipante selezionato?";
          break;
        case "idoneoFalse":
          message =
            "Sei sicuro di voler rendere non idoneo il partecipante selezionato?";
          break;
        case "iscrivi":
          message =
            "Sei sicuro di voler confermare l'iscrizione del partecipante selezionato?";
          break;
        case "rifiuta":
          message =
            "Sei sicuro di voler rifiutare l'iscrizione del partecipante selezionato?";
          break;
        case "scontoTrue":
          message =
            "Sei sicuro di voler modificare l'importo della quota d'iscrizione per il partecipante selezionato?";
          break;
        case "scontoFalse":
          message =
            "Sei sicuro di voler modificare l'importo della quota d'iscrizione per il partecipante selezionato?";
          break;
        case "presenteTrue":
          message =
            "Sei sicuro di voler confermare la presenza del partecipante selezionato?";
          break;
        case "presenteFalse":
          message =
            "Sei sicuro di voler confermare l'assenza del partecipante selezionato?";
          break;
      }
      Swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responseUpdate = ref({});
          switch (tipo) {
            case "remove":
              responseUpdate.value = deletePartecipanteCorso(
                id,
                props.id_Corso,
                id_persona
              );
              break;
            case "idoneoTrue":
              responseUpdate.value = setIdoneoPartecipantiCorso(
                props.id_Corso,
                id,
                azione
              );
              break;
            case "idoneoFalse":
              responseUpdate.value = setIdoneoPartecipantiCorso(
                props.id_Corso,
                id,
                azione
              );
              break;
            case "iscrivi":
              responseUpdate.value = setIscrizionePartecipanteCorso(
                id,
                props.id_Corso,
                id_persona,
                azione
              );
              break;
            case "rifiuta":
              responseUpdate.value = setIscrizionePartecipanteCorso(
                id,
                props.id_Corso,
                id_persona,
                azione
              );
              break;
            case "scontoTrue":
              responseUpdate.value = setScontoPartecipanteCorso(id, azione);
              break;
            case "scontoFalse":
              responseUpdate.value = setScontoPartecipanteCorso(id, azione);
              break;
            case "presenteTrue":
              responseUpdate.value = setPresentePartecipantiCorso(
                props.id_Corso,
                id,
                azione
              );
              break;
            case "presenteFalse":
              responseUpdate.value = setPresentePartecipantiCorso(
                props.id_Corso,
                id,
                azione
              );
              break;
          }
          responseUpdate.value.then((value) => {
            isLoading.value = false;
            if (value.status == 200) {
              Swal.fire({
                html: "Operazione completata con successo",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              switch (tipo) {
                case "idoneoTrue":
                  idoneiArray.value.push(id);
                  nonIdoneiArray.value.splice(
                    nonIdoneiArray.value.indexOf(id),
                    1
                  );
                  break;
                case "idoneoFalse":
                  nonIdoneiArray.value.push(id);
                  idoneiArray.value.splice(idoneiArray.value.indexOf(id), 1);
                  break;
                case "iscrivi":
                  accettataArray.value.push(id);
                  if (inAttesaArray.value.indexOf(id) != -1) {
                    inAttesaArray.value.splice(
                      inAttesaArray.value.indexOf(id),
                      1
                    );
                  }
                  if (nonIscrittoArray.value.indexOf(id) != -1) {
                    nonIscrittoArray.value.splice(
                      nonIscrittoArray.value.indexOf(id),
                      1
                    );
                  }
                  break;
                case "rifiuta":
                  rifiutataArray.value.push(id);
                  if (inAttesaArray.value.indexOf(id) != -1) {
                    inAttesaArray.value.splice(
                      inAttesaArray.value.indexOf(id),
                      1
                    );
                  }
                  if (nonIscrittoArray.value.indexOf(id) != -1) {
                    nonIscrittoArray.value.splice(
                      nonIscrittoArray.value.indexOf(id),
                      1
                    );
                  }
                  break;
                case "scontoTrue":
                  scontoArray.value.push(id);
                  nonScontoArray.value.splice(
                    nonScontoArray.value.indexOf(id),
                    1
                  );
                  break;
                case "scontoFalse":
                  nonScontoArray.value.push(id);
                  scontoArray.value.splice(scontoArray.value.indexOf(id), 1);
                  break;
                case "presenteTrue":
                  presentiArray.value.push(id);
                  assentiArray.value.splice(assentiArray.value.indexOf(id), 1);
                  break;
                case "presenteFalse":
                  assentiArray.value.push(id);
                  presentiArray.value.splice(
                    presentiArray.value.indexOf(id),
                    1
                  );
                  break;
              }
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
          });
        }
      });
    };

    const setAzioniAll = (tipo, id, id_persona, azione) => {
      azione = Boolean(azione);
      let message = "";
      switch (tipo) {
        case "idoneoTrueAll":
          message =
            "Sei sicuro di voler rendere idonei tutti i partecipanti presenti nella lista?";
          break;
        case "idoneoFalseAll":
          message =
            "Sei sicuro di voler rendere non idonei tutti i partecipanti presenti nella lista?";
          break;
        case "presenteTrueAll":
          message =
            "Sei sicuro di voler confermare la presenza di tutti i partecipanti presenti nella lista?";
          break;
        case "presenteFalseAll":
          message =
            "Sei sicuro di voler confermare l'assenza di tutti i partecipanti della lista?";
          break;
      }
      Swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          const responseUpdate = ref({});
          switch (tipo) {
            case "idoneoTrueAll":
              responseUpdate.value = setIdoneoPartecipantiCorso(
                props.id_Corso,
                "ALL",
                azione
              );
              break;
            case "idoneoFalseAll":
              responseUpdate.value = setIdoneoPartecipantiCorso(
                props.id_Corso,
                "ALL",
                azione
              );
              break;
            case "presenteTrueAll":
              responseUpdate.value = setPresentePartecipantiCorso(
                props.id_Corso,
                "ALL",
                azione
              );
              break;
            case "presenteFalseAll":
              responseUpdate.value = setPresentePartecipantiCorso(
                props.id_Corso,
                "ALL",
                azione
              );
              break;
          }
          responseUpdate.value.then((value) => {
            isLoading.value = false;
            if (value.status == 200) {
              Swal.fire({
                html: "Operazione completata con successo",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              emit("getCorsoPartecipantiList");
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
          });
        }
      });
    };

    const changeStatoPagato = (id) => {
      pagatoArray.value.push(id);
      nonPagatoArray.value.splice(nonPagatoArray.value.indexOf(id), 1);
      pagamentoApprovatoArray.value.push(id);
      pagamentoNonApprovatoArray.value.splice(
        pagamentoNonApprovatoArray.value.indexOf(id),
        1
      );
    };

    const selectedIdPartecipante = ref(null);
    const importoPagamento = ref(null);
    const pagamentoIsLoaded = ref(false);

    const setPagamento = (id, importo, partecipante) => {
      selectedIdPartecipante.value = id;
      importoPagamento.value = importo;
      selectedPersona.value = partecipante;
      pagamentoIsLoaded.value = true;
    };

    const zipDocumenti = async (id_persona, id_corso, nominativo_completo) => {
      console.log(nominativo_completo);
      isLoading.value = true;
      await downloadDocumenti(id_persona, id_corso, nominativo_completo).then(
        (res) => {
          console.log(res);
          isLoading.value = false;

          if (res.status != 200) {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        }
      );
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      setIdPersona,
      setPersona,
      selectedIdPersona,
      selectedPersona,
      corso_partecipanti_list,
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordcorso_partecipanti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscorso_partecipanti_list")
      ),
      currentPage: computed(() => store.getters.currentPageCorsoPartecipanti),
      rowsToSkip: computed(() => store.getters.rowsToSkipCorsoPartecipanti),
      fetchRows: computed(() => store.getters.fetchRowsCorsoPartecipanti),
      sortColumn: computed(() => store.getters.sortColumnCorsoPartecipanti),
      sortOrder: computed(() => store.getters.sortOrderCorsoPartecipanti),
      setFetchRowsCorsoPartecipanti,
      setCurrentPageCorsoPartecipanti,
      setSortOrderColumn,
      tableHeader,
      refreshList,
      setAzioni,
      setAzioniAll,
      setPagamento,
      detailIsLoaded,
      id_partecipanti,
      selectedIdPartecipante,
      selectedPersonaObj,
      importoPagamento,
      corsoStagione,
      pagamentoIsLoaded,
      isLoading,
      zipDocumenti,
      getDetailCorso,
      isEnabled,
      esportaLista,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      idoneiArray,
      nonIdoneiArray,
      scontoArray,
      nonScontoArray,
      pagatoArray,
      nonPagatoArray,
      accettataArray,
      rifiutataArray,
      inAttesaArray,
      nonIscrittoArray,
      presentiArray,
      assentiArray,
      pagamentoApprovatoArray,
      pagamentoNonApprovatoArray,

      changeStatoPagato,
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
