<template>
  <div>
    <div class="card shadow mb-5" v-if="isLoaded">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="row pb-4">
          <div class="col">
            <label class="fw-bold text-gray-600">Stagione</label>
            <input
              type="text"
              disabled
              class="form-control"
              :value="jsonViewEditCorso.stagione"
            />
          </div>
          <div class="col">
            <label class="fw-bold text-gray-600">Tipologia corso</label>
            <input
              type="text"
              disabled
              class="form-control"
              :value="
                jsonViewEditCorso.tipologia +
                ' / ' +
                (jsonViewEditCorso.specializzazione_corso || '')
              "
            />
          </div>
          <div class="col">
            <label class="fw-bold text-gray-600">Denominazione</label>
            <input
              type="text"
              disabled
              class="form-control"
              :value="jsonViewEditCorso.denominazione"
            />
          </div>
          <div class="col">
            <label class="fw-bold text-gray-600">Periodo</label>
            <input
              type="text"
              disabled
              class="form-control"
              :value="
                'Dal ' +
                getFormatDate(jsonViewEditCorso.data_inizio) +
                ' al ' +
                getFormatDate(jsonViewEditCorso.data_fine)
              "
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="fw-bold text-gray-600">Luogo</label>
            <input
              type="text"
              disabled
              class="form-control"
              :value="
                jsonViewEditCorso.nazione +
                ' ' +
                jsonViewEditCorso.localita +
                ' ' +
                jsonViewEditCorso.luogo
              "
              v-if="jsonViewEditCorso.estero === '1'"
            />
            <input
              type="text"
              disabled
              class="form-control"
              :value="
                jsonViewEditCorso.regione +
                ' ' +
                jsonViewEditCorso.provincia +
                ' ' +
                jsonViewEditCorso.comune +
                ' ' +
                jsonViewEditCorso.luogo
              "
              v-else
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { detailCorso } from "@/requests/snmCorsi";

export default defineComponent({
  name: "detailCorso",
  emits: ["setStagioneCorso"],
  props: {
    id_Corso: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoaded = ref(false);
    const jsonViewEditCorso = ref({});

    detailCorso(props.id_Corso).then((res) => {
      isLoaded.value = true;
      jsonViewEditCorso.value = res.results[0];
      console.log(
        jsonViewEditCorso.value.stagione,
        jsonViewEditCorso.value.id_tipo_corso
      );
      emit("setStagioneCorso", {
        stagione: jsonViewEditCorso.value.stagione,
        id_tipo_corso: jsonViewEditCorso.value.id_tipo_corso,
      });
    });

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    return { isLoaded, jsonViewEditCorso, getFormatDate };
  },
});
</script>

<style></style>
